import React, { useState } from 'react';
import { Card, Form, Input, Button, message } from 'antd';


const Help = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // 图灵验证逻辑（假设是一个 API 调用）
      const captchaResponse = await fakeCaptchaVerification();
      if (captchaResponse.success) {
        // 发送验证码逻辑（假设是一个 API 调用）
        await fakeSendVerificationCode(values.email);
        message.success('認証コードが送信されました。メールを確認してください。');
      } else {
        message.error('図形検証に失敗しました。もう一度お試しください。');
      }
    } catch (error) {
      message.error('メール送信に失敗しました。');
    } finally {
      setLoading(false);
    }
  };

  // 假设的图灵验证函数
  const fakeCaptchaVerification = async () => {
    return new Promise((resolve) => {
      setTimeout(() => resolve({ success: true }), 1000);
    });
  };

  // 假设的发送验证码函数
  const fakeSendVerificationCode = async (email) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), 1000);
    });
  };

  return (
    <div className="help-page">
      <Card className="help-card">
        <h1>ログインできない場合のヘルプ</h1>
        <p>メールアドレスを入力し、認証コードを取得してください。</p>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            label="メールアドレス"
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                type: 'email',
                message: '有効なメールアドレスを入力してください',
              },
            ]}
          >
            <Input placeholder="メールアドレスを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              認証コードを送信
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Help;